/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../less/cms.less'

const CaPrivacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="cms-container">
        <h1 className="cms-container__title">California Privacy Notice</h1>
        <div className="cms-container__wrapper">
          <p>
            <strong>Effective Date: January 1, 2020.</strong>
          </p>
          <p>
            As of the Effective Date, this Privacy Notice (the "California Notice") supplements the information
            contained in our Privacy Policy and applies solely to individual residents of the State of California
            ("consumers" or "you").
          </p>
          <p>
            This California Notice describes how we collect, disclose, process and otherwise use personal information of
            individual residents of the State of California, either online or offline, within the scope of the
            California Consumer Privacy Act of 2018 ("CCPA")
          </p>
          <p>
            Unless otherwise expressly stated, all terms in the California Notice have the same meaning as defined in
            our Privacy Policy or as defined in the CCPA.
          </p>

          <h2>Personal Information Definition</h2>
          <p>
            The term "personal information" in this California Notice means information that identifies, relates to,
            describes, is reasonably capable of being associated with, or could reasonably be linked, directly or
            indirectly, with a particular consumer or household. The term does not include information that is publicly
            available in government records, is de-identified, aggregated or anonymized information that is held in a
            form that does not provide for it to be associated with an individual, relate to any job applicants,
            employees, contractors or MealPal employees. Personal information also excludes any information excluded
            from the scope of the CCPA. Terms used in this California Notice have the same meaning as their definition
            in the CCPA.
          </p>

          <h2>Collection And Use Of Personal Information</h2>
          <p>
            We collect personal information in connection with our Platform and Services for various purposes. For
            example, we use personal information to:
          </p>
          <ul>
            <li>Communicate with you</li>
            <li>Develop and implement marketing and promotional activities</li>
            <li>Process your subscription renewals, provide customer service and/or provide the MealPal service</li>
            <li>Improve our product offering and come up with new products and services</li>
            <li>For other business and commercial purposes as described in our Privacy Policy</li>
          </ul>

          <p>
            Please review the following sections of the Privacy Policy to learn more about the personal information we
            collect and the purposes for which we collect it: "Collection Of Information" and "Information Use."
          </p>

          <p>In the last 12 months, we have collected the following categories of personal information:</p>
          <div className="table-responsive">
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>Personal Information Category</th>
                  <th>Categories of Sources</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Identifiers</b>, such as name, address, phone number, email address or other similar items
                  </td>
                  <td>
                    <ul>
                      <li>Directly from you</li>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                      <li>
                        Our affiliates and third parties, including third parties you direct to share information with
                        us (e.g. social networks)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>California Customer Records</b>, such as your name, address, phone number, credit card number
                    (including the three or four digit validation code for your credit card)
                  </td>
                  <td>
                    <ul>
                      <li>Directly from you</li>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                      <li>
                        Our affiliates and third parties, including third parties you direct to share information with
                        us (e.g. social networks)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Protected Classification Characteristics</b>, such as age, gender or demographic group
                  </td>
                  <td>
                    <ul>
                      <li>
                        Directly from you if you participate in voluntary surveys MealPal may undertake from time to
                        time, and customers or others may
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Commercial Information</b>, such as your ordering history, records of preferences and tendencies,
                    and account status
                  </td>
                  <td>
                    <ul>
                      <li>Directly from you</li>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                      <li>
                        Our affiliates and third parties, including third parties you direct to share information with
                        us (e.g. social networks)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Internet/Network Information</b>, such as your browsing history, search history, and information
                    about your interaction with the Site
                  </td>
                  <td>
                    <ul>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Geolocation Data</b>, such as information about your physical location collected from geolocation
                    features on your device, including your IP address and GPS (longitude and/or latitude)
                  </td>
                  <td>
                    <ul>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sensory Information</b>, such as recorded calls with our customer service teams
                  </td>
                  <td>
                    <ul>
                      <li>Directly from you</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Inferences</b>, including information generated from your use of the Site reflecting your
                    preferences
                  </td>
                  <td>
                    <ul>
                      <li>
                        Automatically generated or collected during your use of the Site (via your browser or device for
                        example)
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2>Sharing And Disclosure Of Personal Information</h2>
          <p>
            As further described in the section called "Information Sharing and Disclosure" in our Privacy Policy, we
            share personal information with third parties for business purposes, or we may "sell" your personal
            information to third parties, subject to your right to opt out of those sales (see Exercise Your Right to
            Opt-out below). The CCPA defines "sell" broadly to include many different types of data disclosures or data
            sharing to third parties for monetary or other valuable consideration. While MealPal may disclose or
            otherwise share your personal information with select third parties, we do not sell your data to third
            parties in the traditional sense of the phrase, meaning we do not collect, compile and then sell your data
            to data brokers, data resellers or other similar parties.
          </p>

          <p>
            The categories of third parties to whom we sell or disclose your personal information for a business purpose
            include:
          </p>
          <ul>
            <li>Our affiliates</li>
            <li>Restaurant partners</li>

            <li>Employers in instances where a customer is part of an employer sponsored program</li>
            <li>Our service providers and contractors</li>
            <li>Marketing and promotional partners</li>
            <li>Ad networks and advertising partners</li>
            <li>Analytics providers</li>
            <li>Social networks</li>
          </ul>

          <p>
            We may also disclose personal information to third parties at your direction or upon your request, in
            connection with a corporate business transaction, or to comply with legal or contractual obligations, as
            described in our Privacy Policy.
          </p>

          <p>In the last 12 months, we have used data as follows:</p>
          <div className="table-responsive">
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>
                    <b>Personal Information Category</b>
                  </th>
                  <th>
                    <b>Disclosed for a business purpose</b>
                  </th>
                  <th>
                    <b>Disclosed for Sale</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>yes</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>California Customer Records</td>
                  <td>yes</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>Protected Classification Characteristics</td>
                  <td>no</td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>yes</td>
                  <td>yes</td>
                </tr>
                <tr>
                  <td>Internet/Network Information</td>
                  <td>yes</td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>Geolocation Data</td>
                  <td>yes</td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>Sensory Information</td>
                  <td>no</td>
                  <td>no</td>
                </tr>
                <tr>
                  <td>Inferences</td>
                  <td>no</td>
                  <td>no</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            In addition, as is common practice among companies that operate online, we permit third party advertising
            networks, social media companies and other third party businesses to collect and disclose your personal
            information (including Internet / Network Information, Geolocation Data, Commercial Information, and
            Inferences) directly from your browser or device through cookies or tracking technologies when you visit or
            interact with our websites, use our apps or otherwise engage with us.
          </p>

          <h2>Your California Privacy Rights</h2>

          <p>
            As a California resident, you may be able to exercise the following rights (collectively, the "California
            Privacy Rights") in relation to the personal information about you that we have collected (subject to
            certain limitations at law):
          </p>

          <div className="table-responsive">
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>
                    <b>Privacy Right</b>
                  </th>
                  <th>
                    <b>Description</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Right to Know</td>
                  <td>
                    You have the right to request any or all of the following information relating to your personal
                    information we have collected and disclosed in the last 12 months, upon verification of your
                    identity:
                    <ul>
                      <li>The specific pieces of personal information we have collected about you;</li>
                      <li>The categories of personal information we have collected about you;</li>
                      <li>The categories of sources of the personal information;</li>
                      <li>
                        The categories of personal information that we have disclosed to third parties for a business
                        purpose, and the categories of recipients to whom this information was disclosed;
                      </li>
                      <li>
                        The categories of personal information we have sold (as that term is defined by the CCPA) and
                        the categories of third parties to whom the information was sold; and
                      </li>
                      <li>The business or commercial purposes for collecting or selling the personal information.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Right to Delete</td>
                  <td>
                    You have the right to request the deletion of personal information we have collected from you,
                    subject to certain exceptions.
                  </td>
                </tr>
                <tr>
                  <td>Right to Opt-out</td>
                  <td>
                    You have the right to direct us not to sell (as that term is defined by the CCPA) personal
                    information we have collected about you to third parties now or in the future.
                  </td>
                </tr>
                <tr>
                  <td>Right to Non-Discrimination</td>
                  <td>
                    If you choose to exercise your rights, we will not charge you different prices or provide different
                    quality of services unless those differences are related to your personal information. That is, if
                    the exercise of these rights limits our ability to process personal information (such as in the case
                    of a deletion request), we may no longer be able to provide you our products and services or engage
                    with you in the same manner.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2>How To Exercise Your California Privacy Rights</h2>
          <h3>Exercise Your Right to Know or Right to Delete</h3>
          <p>
            You may submit a request to exercise your California Privacy Rights through the mechanisms described below.
            We will need to verify your identity before processing your request, which may require us to request
            additional personal information from you or require you to log into your account. We will only use personal
            information provided in connection with a California Privacy Rights request to review and comply with your
            request. You may also designate an authorized agent to make requests on your behalf. In order to be able to
            act, authorized agents must submit a request using the email address below and provide sufficient evidence
            to demonstrate that the requestor is an authorized agent with written permission to act on your behalf.
          </p>

          <p>
            In certain circumstances, we may decline a request to exercise the Right to Know or Right to Delete
            described above, particularly where we are unable to verify your identity. If we are unable to comply with
            all or a portion of your request, we will explain the reasons for declining to comply with the request.
          </p>

          <p>
            To exercise your Right to Know and/or your Right to Delete, please submit a request by emailing
            privacy@mealpal.com with the subject line "California Privacy Rights Request".
          </p>

          <h3>Exercise Your Right to Opt-out</h3>

          <p>
            Unless you have exercised your Right to Opt-out, we may disclose or "sell" your personal information to
            third parties for monetary or other valuable consideration. The third parties to whom we sell personal
            information may use such information for their own purposes in accordance with their own privacy policies.
            Remember, CCPA defines data "sales" broadly. While MealPal may disclose or otherwise share your personal
            information with select third parties, we do not sell your data to third parties in the traditional sense of
            the phrase, meaning we do not collect, compile and then sell your data to data brokers, data resellers or
            other similar parties.
          </p>

          <p>
            To exercise the Right to Opt-out of personal information sales, please submit a request by emailing
            privacy@mealpal.com with the subject line "California Privacy Right to Opt-out".
          </p>

          <p>
            You do not need to create an account with us to exercise your Right to Opt-out. However, we may ask you to
            provide additional personal information so that we can properly identify you to track compliance with your
            opt-out request. We will only use personal information provided in an opt-out request to review and comply
            with the request. If you choose not to provide this information, we may only be able to process your request
            to the extent we are able to identify you in our data systems.
          </p>

          <h2>Updates To This California Notice</h2>

          <p>
            We will update this California Notice from time to time. When we make changes to this California Notice, we
            will change the "Effective" date at the beginning of this notice. All changes shall be effective from the
            date of publication unless otherwise provided in the notification. If you do not cancel your Account before
            the date the revised California Notice becomes effective, you will be subject to the revised California
            Notice.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or requests in connection with this California Notice or other privacy-related
            matters, please send an email to privacy@mealpal.com.
          </p>

          <p>
            Inquiries may also be sent to:
            <br /> MealPal, Inc.
            <br /> Attention: California Privacy
            <br /> 330 7th Ave,
            <br /> New York, NY 10001.
            <br />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CaPrivacy
